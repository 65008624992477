import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'  

const PrivacyPage = ({ data }) => (  
  <Layout> 
    <Seo
      title={"findbestwebhosting.com ::Privacy Policy"}
      description={
        'findbestwebhosting.com ::Privacy Policy.'
      }
    />
<React.Fragment>
<h2 class="section"><b>
findbestwebhosting.com ::Privacy Policy
   </b></h2>
 
    <p>
    How is the information used that we collect from users?
</p><p>
We will not sell, share, or rent this information to others in ways different from what is disclosed in this policy.
</p><p>
Use of cookies
</p><p>

Cookies are pieces of information that a website stores to a user's hard disk for record-keeping purposes while visiting a site. Findbestwebhosting.com  uses cookies on certain pages of our site. Cookies allows you to save your passwords and preferences for you. We may use cookies to deliver content specific to your interests. Our cookies do not contain any content specific information. Our cookies do not contain any personally identifying information although cookies may enable us to relate your use of our website to personally identifying information that you previously submitted. Most browsers are initially set up to accept cookies, although your browser may permit you to decline cookies. If you do decline cookies, your use of our site may be slowed because you may need to re-enter information.
</p><p>
What are the policies of linked sites and other third parties?
</p><p>
This privacy policy only addresses the use and disclosure of information we collect from you. Although you may interact with hosting suppliers as a result of visiting our site, or link to other sites or third parties via our website, Findbestwebhosting.com  is not responsible for the privacy practices of third parties or the content of linked sites. We encourage you to read the posted privacy policy whenever interacting with any website.
</p><p>
Changes to Privacy Policy?
</p><p>
Findbestwebhosting.com  reserves the right to update this privacy policy from time to time. Please visit this page periodically so that you will be apprised of any changes. However, we will only use Personally Identifiable Information in accordance with the Privacy Policy that was in effect at the time the information was collected. If at any point we should decide to use that information differently, we will notify users by way of E-mail so that users can approve or disapprove of such use.
        </p>

 
    </React.Fragment>
  </Layout>
)

export default PrivacyPage
